/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import NavBar from '../components/Navbar'
import LDGFooter from '../components/LDGFooter'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class PricingView extends React.Component {



  render() {


    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
           <NavBar/>
            <div className="af-class-section">
              <div className="af-class-container">
                <div className="af-class-text-center">
                  <h1 className="af-class-text-gradient-1">We do not have a monthly subscription. Pay for what you use! <a href="https://emojis.wiki/es/rayo/">⚡</a>
                  </h1>
                </div>
                <div className="af-class-small-container">
                  <div className="af-class-text-center">
                    <div className="af-class-text-holder">
                      <h6 className="af-class-text-display">In addition to this, we give you a free ticket for being a new user</h6>
                      <div className="af-class-card-item">
                        <div className="af-class-card-item-content">
                          <div className="af-class-pricing-table-content">
                            <div className="af-class-pricing-header">
                              <h4>Start</h4>
                              <h4 className="af-class-pricing-fee">Free</h4>
                            </div>
                            <div className="af-class-white-40">2 team members</div>
                            <ul role="list" className="af-class-pricing-list">
                              <li className="af-class-list-item-with-check">Unlimited forms</li>
                              <li className="af-class-list-item-with-check">Constant updates</li>
                              <li className="af-class-list-item-with-check">You can create a team</li>
                              <li className="af-class-list-item-with-check">$3 per Ticket</li>
                            </ul>
                            <a href="https://app.listingdescriptiongenerator.com/" target='_blank' className="af-class-button af-class-white-empty w-button">Get Started</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-container">
                <h2 className="af-class-extra-bold-text">Questions? Answers.</h2>
                <div className="w-layout-grid af-class-grid-4">
                  <div id="w-node-bcd54ad8-18ff-4318-a454-cdd9c435fd07-648885a6" className="af-class-content">
                    <div className="af-class-accordion-container">
                      <div className="af-class-accordion-item">
                        <div className="af-class-accordion-item-trigger">
                          <div className="af-class-accordion-icon"><img src="images/arrow-down.svg" loading="lazy" alt className="af-class-accordion-arrow-bottom" /><img src="images/arrow-up.svg" loading="lazy" alt className="af-class-accordion-arrow-up" /></div>
                          <h5>How does the LDG work?</h5>
                        </div>
                        <div className="af-class-accordion-item-content">
                          <p className="af-class-grey-text">The real estate app is very easy to use. Simply complete our 25-question form and, using artificial intelligence, we'll generate an attractive description of your property for potential clients to see.</p>
                        </div>
                      </div>
                      <div className="af-class-accordion-item">
                        <div className="af-class-accordion-item-trigger">
                          <div className="af-class-accordion-icon"><img src="images/arrow-down.svg" loading="lazy" alt className="af-class-accordion-arrow-bottom" /><img src="images/arrow-up.svg" loading="lazy" alt className="af-class-accordion-arrow-up" /></div>
                          <h5>How can I ensure that my description is attractive to potential clients?</h5>
                        </div>
                        <div className="af-class-accordion-item-content">
                          <p className="af-class-grey-text">Our AI system is designed to generate effective and attractive descriptions to help you attract potential clients. Additionally, you can always review the generated description and make adjustments if you wish.</p>
                        </div>
                      </div>
                      <div className="af-class-accordion-item">
                        <div className="af-class-accordion-item-trigger">
                          <div className="af-class-accordion-icon"><img src="images/arrow-down.svg" loading="lazy" alt className="af-class-accordion-arrow-bottom" /><img src="images/arrow-up.svg" loading="lazy" alt className="af-class-accordion-arrow-up" /></div>
                          <h5>How can I update my description if there are changes to my property?</h5>
                        </div>
                        <div className="af-class-accordion-item-content">
                          <p className="af-class-grey-text">You can update your description at any time simply by logging into the app and making the necessary changes to the form. This way, you will always have the most up-to-date description of your property.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_7272be21-d3bd-3d65-b784-2dc1c99974cd-648885a6" className="af-class-content">
                    <div className="af-class-accordion-container">
                      <div className="af-class-accordion-item">
                        <div className="af-class-accordion-item-trigger">
                          <div className="af-class-accordion-icon"><img src="images/arrow-down.svg" loading="lazy" alt className="af-class-accordion-arrow-bottom" /><img src="images/arrow-up.svg" loading="lazy" alt className="af-class-accordion-arrow-up" /></div>
                          <h5>What types of properties can I promote on the app?</h5>
                        </div>
                        <div className="af-class-accordion-item-content">
                          <p className="af-class-grey-text">You can promote any type of property on our app, from houses to apartments, land, and commercial properties.</p>
                        </div>
                      </div>
                      <div className="af-class-accordion-item">
                        <div className="af-class-accordion-item-trigger">
                          <div className="af-class-accordion-icon"><img src="images/arrow-down.svg" loading="lazy" alt className="af-class-accordion-arrow-bottom" /><img src="images/arrow-up.svg" loading="lazy" alt className="af-class-accordion-arrow-up" /></div>
                          <h5>Is there a cost to use the app?</h5>
                        </div>
                        <div className="af-class-accordion-item-content">
                          <p className="af-class-grey-text">Yes, there is a cost to use the app, but we offer different payment plans to fit your needs.</p>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-card-item">
                      <div className="af-class-card-item-content">
                        <div className="af-class-pricing-table-content">
                          <div className="af-class-pricing-header">
                            <h4>Start</h4>
                            <h4 className="af-class-pricing-fee">Free</h4>
                          </div>
                          <div className="af-class-white-40">2 team members</div>
                          <ul role="list" className="af-class-pricing-list">
                            <li className="af-class-list-item-with-check">Unlimited forms</li>
                            <li className="af-class-list-item-with-check">Constant updates</li>
                            <li className="af-class-list-item-with-check">You can create a team</li>
                            <li className="af-class-list-item-with-check">$3 per Ticket</li>
                          </ul>
                          <a href="https://app.listingdescriptiongenerator.com/" target='_blank' className="af-class-button af-class-white-empty w-button">Get Started</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <LDGFooter/>
          </div>
        </span>
      </span>
    )
  }
}

export default PricingView

/* eslint-enable */