/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import NavBar from '../components/Navbar'
import LDGFooter from '../components/LDGFooter'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
  { loading: Promise.resolve("function sendEmail(e){e.preventDefault();var e=document.getElementById(\"Email\").value,n=document.getElementById(\"Topic\").value,e=\`Email: ${e}%0ATopic: ${n}%0AMessage: \`+document.getElementById(\"Message\").value.replace(/\\n/g,\"%0A\"),n=\`mailto:marketing@listingdescriptiongenerator.com?subject=${encodeURIComponent(n)}&body=\`+encodeURIComponent(e);window.location.href=n}"), isAsync: false },
]

let Controller

class ContactView extends React.Component {
  

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '642dd511bde01b5c3788859d'
    htmlEl.dataset['wfSite'] = '642dd511bde01b6f57888589'

    
  }

  render() {
    const proxies = ContactView.Controller !== ContactView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-wrapper">
             <NavBar/>
              <div className="af-class-section af-class-section-grey">
                <div className="af-class-container af-class-no-padding-bottom">
                  <div className="w-layout-grid af-class-grid-10">
                    <div id="w-node-_40d597bf-d666-db68-b7d9-ca3bfe02d20c-3788859d" className="af-class-content af-class-center">
                      <h2>Tell us more about you</h2>
                      <div className="af-class-text-holder">
                        <h6 className="af-class-white-40">We're here to assist you with any questions, comments, or feedback you may have about LDG. Whether you're a broker, real estate agent, or in the industry, we're here to help.</h6>
                      </div>
                    </div>
                    <div id="w-node-_40d597bf-d666-db68-b7d9-ca3bfe02d21e-3788859d" className="af-class-content">
                      <div className="af-class-contact-form-container">
                        <div className="af-class-card-item">
                          <section id="contact-form" className="af-class-contact-form af-class-wf-section">
                            <div className="w-container">
                              <div className="af-class-contact-form-block w-form">
                                <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form" method="get" onsubmit="sendEmail(event)">
                                  <div>
                                    <p>Your Email Address</p><input type="email" className="af-class-form-input w-input" maxLength={256} name="Email" data-name="Email" placeholder="Email" id="Email" required />
                                  </div>
                                  <div>
                                    <p>Write a topic:</p><input type="text" className="af-class-form-input w-input" maxLength={256} name="Topic" data-name="Topic" placeholder="Adding or removing users, Billing, etc" id="Topic" required />
                                  </div>
                                  <div>
                                    <p>Message</p><textarea data-name="Message" maxLength={5000} id="Message" name="Message" placeholder="Message" className="af-class-form-input af-class-textbox w-input" defaultValue={""} />
                                  </div>
                                  <div className="af-class-right-text">
                                    <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="af-class-button af-class-white-filled w-button" />
                                  </div>
                                </form>
                                <div className="af-class-success-message w-form-done">
                                  <div>Thank you! <br />Your submission has been received!</div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section">
                <div className="af-class-container">
                  <div className="af-class-space-small" />
                  <div className="af-class-small-container">
                    <div className="w-layout-grid af-class-grid-6-col af-class-gap-30">
                      <div id="w-node-_310b6777-46d5-8379-4afd-687ad92df0a8-3788859d" className="af-class-card-item-content"><img src="images/feedback-icon.svg" loading="lazy" alt />
                        <h4>General Communication</h4>
                        <div className="af-class-white-40">We're committed to providing exceptional customer service and ensuring your success with LDG.</div>
                      </div>
                      <div id="w-node-_310b6777-46d5-8379-4afd-687ad92df0ae-3788859d" className="af-class-card-item-content"><img src="images/link.svg" loading="lazy" alt />
                        <h4>Technical Support</h4>
                        <div className="af-class-white-40">Our tech support ensures a seamless experience with LDG. Reach us from the form above for prompt assistance. Your success matters to us!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
           <LDGFooter/>
          </div>
        </span>
      </span>
    )
  }
}

export default ContactView

/* eslint-enable */