/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class DownloadView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/DownloadController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = DownloadView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "642dd511bde01ba2978885a0";
    htmlEl.dataset["wfSite"] = "642dd511bde01b6f57888589";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = true;

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      DownloadView.Controller !== DownloadView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <NavBar />
            <div className="af-class-section-hero">
              <div className="af-class-container">
                <div className="af-class-text-center">
                  <div className="af-class-download-icon-holder">
                    <div
                      className="af-class-download-gradient"
                      style={{
                        backgroundImage: `url('/images/DraftAppLogo.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="af-class-download-icon-content">
                      <img
                        src="./images/arrow-down.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-download-down-arrow"
                      />
                    </div>
                  </div>

                  <h1 className="af-class-text-gradient-1">Try LDG WebApp</h1>
                </div>
                <div className="af-class-small-container">
                  <div className="af-class-text-center">
                    <h6 className="af-class-text-display">
                      You do not need to download or install anything, just
                      create an account and start generating your listings{" "}
                      <a href="https://emojipedia.org/es/chispas/">.✨</a>
                    </h6>
                    <div className="af-class-button-container">
                      <a
                        href="https://app.listingdescriptiongenerator.com/"
                        target="_blank"
                        className="af-class-button af-class-empty w-button"
                      >
                        Try it out
                      </a>
                    </div>
                  </div>
                  <div className="af-class-product-demo-sticky">
                    <div className="">
                      <div className="">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2F1.png?alt=media&token=cbe0919a-4eb6-4ef3-b696-b93baa5a0ad5"
                          loading="lazy"
                        />

                        <a
                          href="/#/ldg-showcase"
                          className="af-class-play-icon-holder w-inline-block w-lightbox"
                        >
                          <img
                            src="images/play-icon.svg"
                            loading="lazy"
                            alt
                            className="af-class-play-icon-image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LDGFooter />
          </div>
        </span>
      </span>
    );
  }
}

export default DownloadView;

/* eslint-enable */
