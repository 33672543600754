/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";

class DownloadMobileView extends React.Component {
  render() {
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <NavBar />
            <div className="af-class-section-hero">
              <div className="af-class-container">
                <div className="af-class-text-center">
                  <div className="af-class-download-icon-holder">
                    <div
                      className="af-class-download-gradient"
                      style={{
                        backgroundImage: `url('/images/DraftAppLogo.png')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="af-class-download-icon-content">
                      <img
                        src="images/arrow-down.svg"
                        loading="lazy"
                        alt
                        className="af-class-download-down-arrow"
                      />
                    </div>
                  </div>
                  <h1 className="af-class-text-gradient-2">
                    Download Mobile APP
                  </h1>
                </div>
                <div className="af-class-small-container">
                  <div className="af-class-text-center">
                    <h6 className="af-class-text-display">
                      Generate descriptions from anywhere with the LDG app. It
                      is available for Android and IOS
                    </h6>
                    <div className="af-class-button-container ">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.codingsolved.ldg&pcampaignid=web_share"
                        target="_blank"
                        className="af-class-button af-class-empty w-button"
                      >
                        Android
                      </a>
                      
                      <a
                        href="https://apps.apple.com/us/app/listing-description-generator/id6449767497"
                        target="_blank"
                        className="af-class-button af-class-empty w-button"
                      >
                        IOS
                      </a>
                    </div>
                    <div className="af-class-space-small" />
                  </div>
                  <div
                    data-w-id="e2a4cba9-604a-7c35-1bd4-46dcb6807ccf"
                    className="af-class-hero-product-demo"
                  >
                    <div className="af-class-product-phone-holder">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2Fiphone3.png?alt=media&token=a7ae4c66-965c-4d87-a750-2b1f5f80ed37"
                        loading="lazy"
                        sizes="(max-width: 479px) 32vw, (max-width: 991px) 33vw, (max-width: 1279px) 27vw, (max-width: 1439px) 347.1875px, 391.9921875px"
                        alt
                        className="af-class-product-phone-second"
                      />
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2Fiphone2.png?alt=media&token=67d585d8-10eb-4418-8575-c058df3b9f99"
                        loading="lazy"
                        alt
                        className="af-class-product-phone-second"
                      />
                      <div className="af-class-product-icons-background" />
                    </div>
                    <div className="af-class-product-demo-sticky">
                      <div className="af-class-product-phone-image-container">
                        <div className="af-class-product-container">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2Fiphone1.png?alt=media&token=eccfa047-17fd-4863-8d7c-bed9273be67a"
                            loading="lazy"
                            alt
                            className="af-class-product-phone-image"
                          />
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2Fiphone1.png?alt=media&token=eccfa047-17fd-4863-8d7c-bed9273be67a"
                            loading="lazy"
                            alt
                            className="af-class-product-phone-demo-image"
                          />
                          <a
                            href="/#/ldg-showcase"
                            className="af-class-play-icon-holder w-inline-block w-lightbox"
                          >
                            <img
                              src="images/play-icon.svg"
                              loading="lazy"
                              alt
                              className="af-class-play-icon-image"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LDGFooter />
          </div>
        </span>
      </span>
    );
  }
}

export default DownloadMobileView;

/* eslint-enable */
