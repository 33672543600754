import React, { useEffect } from "react";
import NavBar from "./Navbar";
import LDGFooter from "./LDGFooter";
import ReactPlayer from "react-player";

function LDGPlayer() {
    useEffect(() => {
        window.scrollTo(0, 0); // Esto desplaza la ventana al inicio de la página
      }, []);
  return (
    <span>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
        }}
      />
      <span className="af-view">
        <NavBar />
        <div className="af-class-section-hero">
          <div style={{ display: "flex", justifyContent: "center", marginTop: "5vh" }}>
            <ReactPlayer
              url="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FV1_LDG_1920x1080.mp4?alt=media&token=73903ecf-aaea-4252-94a7-6a8910ffba4d"
              height={"80vh"}
              width={"90vw"}
              
              volume={1}
              playing={true}
              controls={true}

            />

          </div>
        </div>
        <LDGFooter />
      </span>
    </span>
  );
}

export default LDGPlayer;
