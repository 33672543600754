/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import NavBar from '../components/Navbar'
import LDGFooter from '../components/LDGFooter'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://www.gstatic.com/firebasejs/8.3.1/firebase-app.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://www.gstatic.com/firebasejs/8.3.1/firebase-firestore.js").then(body => body.text()), isAsync: false },
]

let Controller

class BlogView extends React.Component {
 

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '642dd511bde01b091288859a'
    htmlEl.dataset['wfSite'] = '642dd511bde01b6f57888589'

    
  }

  render() {
    const proxies = BlogView.Controller !== BlogView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-wrapper">
              <NavBar />
              <div className="af-class-section af-class-section-grey">
                <div className="af-class-container">
                  <div className="w-layout-grid af-class-grid-10">
                    <div id="w-node-_4479bb91-31c7-e730-eaed-fd4f95ad34b6-1288859a" className="af-class-content af-class-center">
                      <h1 className="af-class-text-gradient-1">Thoughts, Notes &amp; Tutorials</h1>
                      <div className="af-class-text-holder">
                        <h6 className="af-class-white-40">
                          Stay up to date with our latest analysis and tips when it
                          comes to real estate.
                        </h6>
                      </div>
                      <div id="Subscribe" className="af-class-subscribe-form w-form">
                        <form id="wf-form-Subscribe-Form" name="wf-form-Subscribe-Form" data-name="Subscribe Form" method="get" className="af-class-subscribe-form-flex">
                          <div className="af-class-subscribe-form-input-wrapper">
                            <input type="email" className="af-class-subscribe-input w-input" maxLength={256} name="Subscriber-Email" data-name="Subscriber Email" placeholder="Email" id="Subscriber-Email" required />
                          </div>
                          <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="af-class-button af-class-btn-subscribe w-button" />
                        </form>
                        <div className="af-class-success-message w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="af-class-error-message w-form-fail">
                          <div>
                            Oops! Something went wrong while submitting the form.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_4479bb91-31c7-e730-eaed-fd4f95ad34bf-1288859a" className="af-class-content">
                      <img src="images/group-23x.png" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 1279px) 96vw, (max-width: 1439px) 1240px, 1277.98828125px" srcSet="
                    images/group-23x-p-800.png   800w,
                    images/group-23x-p-1080.png 1080w,
                    images/group-23x.png        1278w
                  " alt className="af-class-image-100" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section">
                <div className="af-class-container">
                  <div className="w-dyn-list">
                    <div role="list" className="w-dyn-items">
                      <div role="listitem" className="af-class-collection-item w-dyn-item">
                        <a href="#" className="af-class-post-item w-inline-block">
                          <div className="w-layout-grid af-class-blog-featured-grid">
                            <div id="w-node-_986f29b6-8617-52ec-89ea-f40121739289-1288859a" className="af-class-content">
                              <div className="af-class-blog-item-image-container">
                                <img loading="lazy" src alt className="af-class-post-item-image" />
                                <div className="af-class-blog-item-overlay">
                                  <h5 className="af-class-heading">Read Article</h5>
                                </div>
                              </div>
                            </div>
                            <div id="w-node-_578ee373-e8e9-7b43-88b6-79bc28c4ba3a-1288859a" className="af-class-featured-blog-item-content">
                              <h3 />
                              <div className="af-class-featured-blog-sub-content">
                                <h6 className="af-class-blog-item-date" />
                                <h6 className="af-class-blog-item-category" />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="w-dyn-empty">
                      <div>No items found.</div>
                    </div>
                  </div>
                  <div className="af-class-gradient-line af-class-line-margin" />
                  <div className="af-class-space-x-small" />
                  <div className="w-dyn-list">
                    <div role="list" className="w-dyn-items w-row">
                      <div role="listitem" className="af-class-post-collection-item w-dyn-item w-col w-col-4">
                        <a href="#" className="af-class-post-item w-inline-block">
                          <div className="af-class-blog-item-image-container">
                            <img loading="lazy" src alt className="af-class-post-item-image" />
                            <div className="af-class-blog-item-overlay" />
                          </div>
                          <h6 className="af-class-blog-item-category" />
                          <h5 />
                          <h6 className="af-class-blog-item-date" />
                        </a>
                      </div>
                    </div>
                    <div className="w-dyn" id="dynamicContent">
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          <LDGFooter/>
            {/* The core Firebase JS SDK is always required and must be listed first */}
            {/* TODO: Add SDKs for Firebase products that you want to use
            https://firebase.google.com/docs/web/setup#available-libraries */}
          </div>
        </span>
      </span>
    )
  }
}

export default BlogView

/* eslint-enable */