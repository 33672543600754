/* eslint-disable */

import React from "react";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";
import PageWrapper from "./PageWrapper";
import SectionInformation from "../components/SectionInformation";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: true,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];  


class IndexView extends React.Component {
  componentDidMount() {
    this.loadScripts();
  }

  loadScripts(attempt = 1) {
    const maxAttempts = 5;
    const retryDelay = 3000; // tiempo en milisegundos antes de reintentar

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          return next;
        }).catch((error) => {
          console.error(`Error al cargar el script. Intento ${attempt} de ${maxAttempts}: `, error);
          if (attempt < maxAttempts) {
            setTimeout(() => this.loadScripts(attempt + 1), retryDelay);
          }
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
   

    return (
      <PageWrapper>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <NavBar />
            <div className="af-class-section-hero">
              <div className="af-class-container">
                <div className="af-class-text-center">
                  <h1 className="af-class-hero-text">
                    <span className="af-class-text-gradient-1">Listing</span>
                  </h1>
                  <h1 className="af-class-hero-text">
                    <span className="af-class-text-gradient-2">
                      Description Generator
                    </span>
                  </h1>
                </div>
                <div className="af-class-small-container">
                  <div className="af-class-text-center">
                    <div className="af-class-text-holder">
                      <h6 className="af-class-text-display">
                        Streamline your property descriptions. Answer a few
                        questions and generate high-quality, customizable real
                        estate listings in minutes.
                      </h6>
                      <div className="af-class-preloader">
                        <div className="af-class-preloader-content">
                          <div className="af-class-preloader-line">
                            <div className="af-class-preloader-gradient" />
                          </div>
                          <img src="images/ldg.logo.png" loading="lazy" alt />
                        </div>
                      </div>
                      <h6 className="af-class-text-display">
                        ‍<strong>Fast, affordable, and effortless.</strong>
                      </h6>
                    </div>
                    <div className="af-class-button-container">
                      <a
                        href="http://app.listingdescriptiongenerator.com/"
                        target="_blank"
                        className="af-class-button af-class-empty w-button"
                      >
                        Generate Your Property Description
                      </a>
                    </div>
                  </div>
                  <div
                    data-w-id="3d8609da-3448-387d-1e0e-10521ed65395"
                    className="af-class-hero-product-demo"
                  >
                    <div className="af-class-product-macbookholder">
                      <img
                        src="images/macbook2x.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 92vw, (max-width: 991px) 95vw, (max-width: 1279px) 77vw, (max-width: 1439px) 991.9921875px, 1120px"
                        srcSet="images/macbook2x-p-500.png 500w, images/macbook2x-p-800.png 800w, images/macbook2x-p-1080.png 1080w, images/macbook2x-p-1600.png 1600w, images/macbook2x.png 1792w"
                        alt
                      />
                      <div className="af-class-product-icons-background" />
                    </div>
                    <div className="af-class-product-demo-sticky">
                      <div className="af-class-product-image-container">
                        <div className="af-class-product-container af-class-desktop">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%2012.21.44%E2%80%AFp.m..png?alt=media&token=551d1ffc-9cdf-4cc9-bdf1-abaeb589c6cc"
                            loading="lazy"
                            sizes="(max-width: 479px) 77vw, (max-width: 767px) 74vw, (max-width: 991px) 75vw, (max-width: 1279px) 59vw, (max-width: 1439px) 771.9921875px, 880px"
                            alt
                            className="af-class-product-image"
                          />
                         <a
                          href="/#/ldg-showcase"
                          className="af-class-play-icon-holder w-inline-block w-lightbox"
                        >
                          <img
                            src="images/play-icon.svg"
                            loading="lazy"
                            alt
                            className="af-class-play-icon-image"
                          />
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-container">
                <div className="af-class-small-container">
                  <div className="af-class-customers-panel">
                    <div className="af-class-text-holder">
                      <div className="af-class-text-center">
                        <div className="af-class-small-text">
                          These teams can confirm it
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-grid-6-col">
                      <div className="af-class-content af-class-centered">
                        <img
                          src="images/c2cLogoWhite.png"
                          loading="lazy"
                          height={200}
                          width={100}
                          style={{ objectFit: "contain" }}
                          alt
                          className="af-class-customer-image"
                        />
                      </div>
                      <div
                        id="w-node-bb922d9d-ad80-ca90-7103-737819bc289b-8d88858c"
                        className="af-class-content af-class-centered"
                      >
                        <img
                          src="images/HannanGroup.png"
                          loading="lazy"
                          width={200}
                          height={100}
                          style={{ objectFit: "contain" }}
                          alt
                          className="af-class-customer-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-text-center">
                    <h3>No installation ⚡️</h3>
                    <p className="af-class-grey-text">
                      Time is money, and with LDG, you can save both. Our
                      easy-to-use platform takes just minutes to generate a
                      professional-grade listing description, freeing you up to
                      focus on other important tasks that can help you grow your
                      business.
                    </p>
                  </div>
                </div>
                <div className="af-class-horizontal-line" />
              </div>
            </div>
            <SectionInformation 
            title = "Just say 💬 it"
            description = "With our immediate delivery, you can start using your new listing descriptions right away"
            menuItems = {[
              {
                title : "Fast and intuitive",
                description: "Get top-notch listings in minutes with LDG. Save time and money with our user-friendly platform.",
                icon: "images/aurora-icon.svg",
                image: "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%2012.07.01%E2%80%AFp.m..png?alt=media&token=7e69a33f-d2ad-4924-83ac-4afa0cf81116"
              },
              {
                title : "Contextual feedback",
                description: "Tailored descriptions that elevate your listing. LDG offers concise, market-specific feedback for a standout presence.",
                icon: "images/feedback-icon.svg",
                image: "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%2012.32.30%E2%80%AFp.m..png?alt=media&token=2fa609cb-9dce-48b4-9e34-ccbd6827de5c"
              },
              {
                title : "Cloud Storage",
                description: "Manage your listings anytime, anywhere. Collaborate effortlessly with cloud-based access and real-time updates.",
                icon: "images/cloud-icon.svg",
                image: "images/642dd511bde01bf86c8885be_productscreen-022x-p-800.png"
              },
              {
                title : "No Guesswork",
                description: "Efficient and precise, LDG removes the guesswork. Create compelling listings with ease and confidence.",
                icon: "images/integrated-icon.svg",
                image: "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%2012.21.44%E2%80%AFp.m..png?alt=media&token=551d1ffc-9cdf-4cc9-bdf1-abaeb589c6cc"
              },
            ]}

            
            />
            <div className="af-class-section af-class-section-grey">
              <div className="af-class-container">
                <h2>Work more easily with LDG</h2>
                <div className="af-class-text-container">
                  <p className="af-class-grey-text">
                    Time is money, and with LDG, you can save both. Our
                    easy-to-use platform takes just minutes to generate a
                    professional-grade listing description
                  </p>
                </div>
                <div className="w-layout-grid af-class-feature-grid af-class-gap-30">
                  <a
                    id="w-node-c6f5231e-b84e-5819-702a-e79ddfb4d30e-8d88858c"
                    href="#"
                    className="af-class-card-item af-class-fadein-into-view af-class-change-color w-inline-block"
                  >
                    <div className="af-class-card-item-content">
                      <img src="images/aurora-icon.svg" loading="lazy" alt />
                      <h4>Built with speed in mind</h4>
                      <div className="af-class-white-40">
                        With our algorithm, you get your listing description
                        immediately with real time regeneration.
                      </div>
                    </div>
                    <div className="af-class-card-item-gradient" />
                  </a>
                  <a
                    id="w-node-_990d4a21-f4d1-bfd2-cef0-dd08978e7111-8d88858c"
                    href="#"
                    className="af-class-card-item af-class-fadein-into-view af-class-change-color w-inline-block"
                  >
                    <div className="af-class-card-item-content">
                      <img src="images/direction-icon.svg" loading="lazy" alt />
                      <h4>Work from anywhere</h4>
                      <div className="af-class-white-40">
                        We give you the freedom to work from anywhere, so you
                        can be productive and efficient no matter where you are.
                      </div>
                    </div>
                    <div className="af-class-card-item-gradient" />
                  </a>
                  <a
                    id="w-node-_09799c7f-d5d6-7a86-e9dc-28d94f9e33a4-8d88858c"
                    href="#"
                    className="af-class-card-item af-class-fadein-into-view af-class-change-color w-inline-block"
                  >
                    <div className="af-class-card-item-content">
                      <h4>Create and share in one tool</h4>
                    </div>
                    <div className="af-class-card-item-gradient af-class-gradient-2" />
                  </a>
                  <a
                    id="w-node-c78f6b20-2ce1-735e-8d8f-471198416976-8d88858c"
                    href="#"
                    className="af-class-card-item af-class-fadein-into-view af-class-change-color w-inline-block"
                  >
                    <div className="af-class-card-item-content">
                      <h4>On every device</h4>
                    </div>
                    <div className="af-class-card-item-gradient af-class-gradient-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-section-grey">
              <div className="af-class-container">
                <div className="af-class-text-center">
                  <h2>Feedback ⭐️</h2>
                </div>
                <div className="af-class-slider-container">
                  <div
                    data-delay={3000}
                    data-animation="slide"
                    className="af-class-slider w-slider"
                    data-autoplay="true"
                    data-easing="ease"
                    data-hide-arrows="false"
                    data-disable-swipe="false"
                    data-autoplay-limit={0}
                    data-nav-spacing={3}
                    data-duration={300}
                    data-infinite="true"
                  >
                    <div className="af-class-slider-mask w-slider-mask">
                      <div className="af-class-slide w-slide">
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-1.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  LDG did a great job taking a plain listing and
                                  turning it into the best I've seen in years!
                                  <br />
                                  I&nbsp;appreciate that.
                                </p>
                                <p className="af-class-feedback-author">
                                  Emily Johnson
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-slide w-slide">
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-6.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  The listing description generator has been a
                                  game-changer for my real estate business.
                                </p>
                                <p className="af-class-feedback-author">
                                  Samantha Rodriguez
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-w-id="af3cd836-e1a3-4233-f63c-beb7bfec406e"
                        className="af-class-slide w-slide"
                      >
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-3.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  I am absolutely blown away by the quality of
                                  the work that LDG has done for me. Their
                                  attention to detail and ability to take my
                                  ideas and turn them into something truly
                                  amazing is simply incredible.
                                </p>
                                <p className="af-class-feedback-author">
                                  Benjamin Lee
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-slide w-slide">
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-5.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  I can't thank LDG enough for the amazing work
                                  that they've done for me. Their commitment to
                                  excellence is second to none, and I feel lucky
                                  to have had the opportunity to work with them.
                                </p>
                                <p className="af-class-feedback-author">
                                  Grace Nguyen
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-slide w-slide">
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-4.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  LDG has revolutionized the way I approach
                                  digital marketing. Their creative solutions
                                  and attention to detail have made a
                                  significant impact on my business. I highly
                                  recommend their services to anyone looking to
                                  take their online presence to the next level.
                                </p>
                                <p className="af-class-feedback-author">
                                  Natalie Brown
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-slide w-slide">
                        <div className="af-class-feedback-card">
                          <div className="af-class-card-item">
                            <div className="af-class-card-item-content">
                              <div className="af-class-content af-class-centered">
                                <div className="af-class-feedback-image-holder">
                                  <img
                                    src="images/User-2.jpg"
                                    loading="lazy"
                                    alt
                                    className="af-class-feedback-image"
                                  />
                                </div>
                                <p className="af-class-text-regular af-class-text-center">
                                  Working with LDG has been an absolute
                                  pleasure. They take the time to really
                                  understand your needs and deliver customized
                                  solutions that exceed your expectations. Their
                                  professionalism and expertise are truly
                                  impressive, and I would not hesitate to work
                                  with them again in the future.
                                </p>
                                <p className="af-class-feedback-author">
                                  Isabella Thompson
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-slider-left-arrow w-slider-arrow-left">
                      <div className="w-icon-slider-left" />
                    </div>
                    <div className="af-class-slider-right-arrow w-slider-arrow-right">
                      <div className="w-icon-slider-right" />
                    </div>
                    <div className="af-class-slider-nav w-slider-nav w-round" />
                  </div>
                </div>
              </div>
            </div>
                <LDGFooter />
            <div className="af-class-preloader">
              <div className="af-class-preloader-content">
                <div className="af-class-preloader-line">
                  <div className="af-class-preloader-gradient" />
                </div>
                <img src="images/ldg.logo.png" loading="lazy" alt />
              </div>
            </div>
          </div>
        </span>
      </PageWrapper>
    );
  }
}

export default IndexView;

/* eslint-enable */
