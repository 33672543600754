import React, { useState } from "react";

function renderTabLink(item, index, activeTab, setActiveTab) {
  return (
    <a
      key={index}
      data-w-tab={`Tab ${index + 1}`}
      className={`af-class-tab-link w-inline-block w-tab-link ${
        index === activeTab ? "w--current" : ""
      }`}
      onClick={() => setActiveTab(index)}
    >
      <div className="af-class-tab-icon-holder">
        <div
          className={`af-class-tab-icon-background ${
            index === activeTab ? "af-class-tab-icon-background-active" : ""
          }`}
        />
        <img loading="lazy" src={item.icon} alt className="af-class-tab-icon" />
      </div>
      <h4 className="af-class-tab-title">{item.title}</h4>
      <p className="af-class-tab-description">{item.description}</p>
    </a>
  );
}

function SectionInformation(props) {
  const { title, description, menuItems = [] } = props;
  const [activeTab, setActiveTab] = useState(0); // Inicia con la primera pestaña activa
  const [transitionImage, setTransitionImage] = useState(menuItems[0].image);

  const handleChangeTab = (index, image) => {
    setActiveTab(index);
    setTransitionImage(image);
  };
  return (
    <>
      <div className="af-class-section">
        <div className="af-class-container">
        <div className="af-class-text-center">
                <h2>{title}</h2>

                <div className="af-class-text-container">
                  <p className="af-class-grey-text">
                   {description}
                  </p>
                </div>
              </div>
          <div className="af-class-tabs w-tabs">
            <div className="af-class-tabs-menu w-tab-menu">
              {menuItems.map((item, index) => (
                <a
                  key={index}
                  className={`af-class-tab-link w-inline-block w-tab-link ${
                    index === activeTab ? "w--current" : ""
                  }`}
                  onClick={() => handleChangeTab(index, item.image)}
                >
                  {renderTabLink(item, index, activeTab, setActiveTab)}
                </a>
              ))}
            </div>
            <div className="af-class-tab-content">
              <div className="af-class-tab-pane">
                <img
                  src={transitionImage}
                  loading="lazy"
                  alt=""
                  className="af-class-tab-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionInformation;
