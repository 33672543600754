import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
const menuItems = [
  { path: "/#/ldg-hosting", text: "LDG Hosting", isNew: true },
  { path: "/#/agent-ai", text: "Agent AI", isNew: true },
  { path: "/#/pricing", text: "Pricing", isNew: false },
  { path: "/#/blog", text: "Blog", isNew: false },
  { path: "/#/contact", text: "Contact Us", isNew: false },
  // Añade más elementos según sea necesario
];

const renderMenuItem = (item) => (
  <a href={item.path} className="af-class-nav-link w-inline-block">
    <div className="af-class-nav-link-text">{item.text}</div>
    {item.isNew && <div className="new-indicator">NEW</div>}
  </a>
);
class NavBar extends React.Component {
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @import url(/css/normalize.css);
            @import url(/css/webflow.css);
            @import url(/css/ldg-7a93c9.webflow.css);
          `,
          }}
        />
        <div
          data-collapse="small"
          data-animation="default"
          data-duration={400}
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="af-class-fixed-nav w-nav"
        >
          <header className="af-class-container af-class-nav-container w-container">
            <a
              href="/"
              aria-current="page"
              className="af-class-brand w-nav-brand w--current"
            >
              <img
                src="images/LDSLogo.png"
                width={200}
                height={150}
                wi
                alt
                className="af-class-nav-logo"
              />
            </a>
            <nav role="navigation" className="af-class-nav-menu w-nav-menu">
              {menuItems.map(renderMenuItem)}
              <div className="af-class-nav-cta-button">
                <a
                  href="/#/download"
                  className="af-class-button af-class-grey af-class-small w-inline-block"
                >
                  <div className="af-class-nav-link-text">Try it out</div>
                  <img
                    src="images/arrow-right-white-icon.svg"
                    loading="lazy"
                    alt
                    className="af-class-button-icon"
                  />
                </a>
              </div>
            </nav>
            {/* <div className="af-class-menu-button w-nav-button">
              <img src="images/menu-icon-white.svg" loading="lazy" alt />
            </div> */}
            <div className="burgermenu">
              <Menu
                right
                pageWrapId={"page-wrap"}
                styles={{
                  bmBurgerBars: { backgroundColor: "#fff" },
                  bmBurgerButton: {
                    position: "fixed",
                    width: "36px",
                    height: "30px",
                    right: "36px",
                    top: "26px",
                  },
                  bmCrossButton: { height: "24px", width: "24px" },
                  bmMenuWrap: { position: "fixed", height: "100%" },
                  bmMenu: {
                    background: "#000",
                    padding: "2.5em 1.5em 0",
                    fontSize: "1.15em",
                  },
                  bmMorphShape: { fill: "#fff" },
                }}
              >
                {menuItems.map(renderMenuItem)}
                <div className="af-class-nav-cta-button">
                  <a
                    href="/#/download"
                    className="af-class-button af-class-grey af-class-small w-inline-block"
                  >
                    <div className="af-class-nav-link-text">Try it out</div>
                    <img
                      src="images/arrow-right-white-icon.svg"
                      loading="lazy"
                      alt
                      className="af-class-button-icon"
                    />
                  </a>
                </div>
              </Menu>
            </div>
          </header>

          <div className="af-class-nav-gradient-container">
            <div className={"loaded-line"}></div>
          </div>
        </div>
      </>
    );
  }
}

export default NavBar;
