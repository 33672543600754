import React from "react";

function LDGFooter() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
        }}
      />
      <div className="af-class-footer">
        <div className="af-class-gradient-line" />
        <div className="af-class-container w-container">
          <div className="af-class-footer-flex-container">
            <div
              id="w-node-_9a452d70-0c5d-8400-4e47-0636058de355-465cdbb7"
              className="af-class-footer-flex-first"
            >
              <a href="#" className="af-class-footer-logo-link w-inline-block">
                <img
                  src="images/LDGLogo.png"
                  width={100}
                  height={100}
                  alt
                  className="af-class-footer-image"
                />
              </a>
              <div className="af-class-grey-text">
                Streamline your property descriptions. Answer a few questions
                and generate high-quality, customizable real estate listings in
                minutes.
              </div>
            </div>
            <div className="af-class-footer-flex-content">
              <div className="af-class-grey-text af-class-footer-heading">
                Explorer
              </div>
              <ul role="list" className="w-list-unstyled">
                <li>
                  <a href="/#/download" className="af-class-link w-inline-block">
                    <div>Website </div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
                <li>
                  <a
                    href="/#/download-mobile"
                    className="af-class-link w-inline-block"
                  >
                    <div>Mobile App</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="af-class-footer-flex-content">
              <div className="af-class-grey-text af-class-footer-heading">
                Legal
              </div>
              <ul role="list" className="w-list-unstyled">
                <li>
                  <a
                    href="https://codingsolved.com/ldg-privacy-policy"
                    target="_blank"
                    className="af-class-link w-inline-block"
                  >
                    <div>Privacy</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
                <li>
                  <a href="/#/licensing" className="af-class-link w-inline-block">
                    <div>Licensing</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="af-class-footer-flex-content">
              <div className="af-class-grey-text af-class-footer-heading">
                Company
              </div>
              <ul role="list" className="w-list-unstyled">
                <li>
                  <a href="/#/company" className="af-class-link w-inline-block">
                    <div>About</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
                <li>
                  <a href="/#/contact" className="af-class-link w-inline-block">
                    <div>Contact</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
                <li>
                  <a href="/#/blog" className="af-class-link w-inline-block">
                    <div>Blog</div>
                    <div className="af-class-link-underline" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="af-class-space-small" />
          <div className="af-class-footer-flex-container">
            <div
              id="w-node-_9a452d70-0c5d-8400-4e47-0636058de37f-465cdbb7"
              className="af-class-grey-text af-class-footer-grey-text-holder"
            >
              <div
                data-w-id="99bc6bd0-837d-9882-425d-1889465cdbce"
                className="af-class-footer-flex-links-holder"
              >
                <div className="af-class-crafted-by">
                  Crafted with <span>💛 </span>&nbsp;by
                </div>
                <div className="af-class-horizontal-link-holder">
                  <a
                    href="https://codingsolved.com"
                    target="_blank"
                    className="w-inline-block"
                  >
                    <div>Coding Solved</div>
                  </a>
                </div>
              </div>
              <div
                data-w-id="fb0c8e6c-5c62-d97e-b710-b5567bd343c5"
                className="af-class-footer-flex-links-holder"
              />
            </div>
            <div
              id="w-node-a6a20bdc-6572-7faf-f431-a40c4ded3dd8-465cdbb7"
              className="af-class-right-align-footer-links-holder"
            >
              <div className="af-class-content">
                <a
                  data-w-id="99bc6bd0-837d-9882-425d-1889465cdbd4"
                  href="/#/licensing"
                  className="af-class-link w-inline-block"
                >
                  <div>Licensing</div>
                  <div className="af-class-link-underline" />
                </a>
              </div>
              <div className="af-class-content">
                <a
                  data-w-id="99bc6bd0-837d-9882-425d-1889465cdbe3"
                  href="change-log.html"
                  className="af-class-link w-inline-block"
                >
                  <div>Change Log</div>
                  <div className="af-class-link-underline" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LDGFooter;
