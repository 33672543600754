import React from "react";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";
import PageWrapper from "./PageWrapper";
import SectionInformation from "../components/SectionInformation";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: true,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

class LDGHosting extends React.Component {
  componentDidMount() {
    this.loadScripts();
  }

  loadScripts(attempt = 1) {
    const maxAttempts = 5;
    const retryDelay = 3000; // tiempo en milisegundos antes de reintentar

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading
          .then((script) => {
            return next;
          })
          .catch((error) => {
            console.error(
              `Error al cargar el script. Intento ${attempt} de ${maxAttempts}: `,
              error
            );
            if (attempt < maxAttempts) {
              setTimeout(() => this.loadScripts(attempt + 1), retryDelay);
            }
          });
        console.group();

        console.log("next", next);
        console.log("loading", loading);
        console.groupEnd();
        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    return (
      <PageWrapper>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <NavBar />
          <div className="af-class-section-hero">
            <div className="af-class-container">
              <div className="af-class-text-center">
                <h1 className="af-class-hero-text">
                  <span className="af-class-text-gradient-1">LDG</span>
                </h1>
                <h1 className="af-class-hero-text">
                  <span className="af-class-text-gradient-2">Hosting</span>
                </h1>
              </div>
              <div className="af-class-small-container">
                <div className="af-class-text-center">
                  <div className="af-class-text-holder">
                    <h6 className="af-class-text-display">
                      Elevate your property's profile with LDG Hosting: A Global
                      Real Estate Platform. Beyond visual allure, our AI-driven
                      narratives captivate and engage, drawing a global audience
                      to the unique story of your home.
                    </h6>

                    <div className="af-class-preloader">
                      <div className="af-class-preloader-content">
                        <div className="af-class-preloader-line">
                          <div className="af-class-preloader-gradient" />
                        </div>
                        <img src="images/ldg.logo.png" loading="lazy" alt />
                      </div>
                    </div>
                    <h6 className="af-class-text-display">
                      ‍<strong>Fast, affordable, and effortless.</strong>
                    </h6>
                  </div>
                  <div className="af-class-button-container">
                    <a
                      href="http://app.listingdescriptiongenerator.com/"
                      target="_blank"
                      className="af-class-button af-class-empty w-button"
                    >
                      Get Started
                    </a>
                  </div>
                </div>

                <div className="af-class-product-demo-sticky">
                  <div className="af-class-product-image-container">
                    <div className="af-class-product-container af-class-desktop">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2F3.png?alt=media&token=7a00d53f-06d3-48d8-8c67-7451b0a3e693"
                        loading="lazy"
                        sizes="(max-width: 479px) 77vw, (max-width: 767px) 74vw, (max-width: 991px) 75vw, (max-width: 1279px) 59vw, (max-width: 1439px) 771.9921875px, 880px"
                        alt
                        className="af-class-product-image"
                      />
                      <a
                        href="#"
                        className="af-class-play-icon-holder w-inline-block w-lightbox"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionInformation
            title="🌍 Reach Globally, Effortlessly"
            description=" It will only take you a couple of minutes and you can be showing your properties to the world."
            menuItems={[
              {
                title: "AI-Powered Publishing",
                description:
                  "Revolutionize property listings with AI. Create engaging, smart descriptions effortlessly.",
                icon: "images/aurora-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%203.18.38%E2%80%AFp.m..png?alt=media&token=27dc4fba-125b-4240-b784-59c6bce1aaed",
              },
              {
                title: "Intelligent Descriptions",
                description:
                  "Craft perfect listings. Our AI analyzes every detail for maximum market appeal.",
                icon: "images/feedback-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%203.18.58%E2%80%AFp.m..png?alt=media&token=84f92b9d-660e-4423-9ad5-e3ecd91f4a8b",
              },
              {
                title: "Cloud Collaboration",
                description:
                  "Manage listings from anywhere. Real-time updates and teamwork made easy with cloud technology.",
                icon: "images/cloud-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%203.34.53%E2%80%AFp.m..png?alt=media&token=57956969-1294-486c-887f-b44b6419eece",
              },
              {
                title: "Streamlined Creation",
                description:
                  "Say goodbye to guesswork. Efficient, intuitive tools for standout property listings.",
                icon: "images/integrated-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%203.13.39%E2%80%AFp.m..png?alt=media&token=3db6ccac-d6f9-4c6d-a654-dcc0f75273e7",
              },
            ]}
          ></SectionInformation>

          <LDGFooter />
        </span>
      </PageWrapper>
    );
  }
}

export default LDGHosting;
