// PageWrapper.js
import React, { useState, useEffect } from 'react';
import PreLoader from '../components/PreLoader';
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
    {
      loading: fetch(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
      ).then((body) => body.text()),
      isAsync: false,
    },
    {
      loading: fetch("js/webflow.js").then((body) => body.text()),
      isAsync: false,
    },
  ];
class PageWrapper extends React.Component {


  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "642dd511bde01b468d88858c";
    htmlEl.dataset["wfSite"] = "642dd511bde01b6f57888589";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const { children } = this.props;

      

    return <>{children}</>;
  }
}

export default PageWrapper;