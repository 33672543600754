import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import "./App.css";

import IndexView from "./views/IndexView";
import BlogView from "./views/BlogView";
import PricingView from "./views/PricingView";
import ContactView from "./views/ContactView";
import DownloadView from "./views/DownloadView";
import DownloadMobileView from "./views/DownloadMobileView";
import LicensingView from "./views/LicensingView";
import CompanyView from "./views/CompanyView";
import LDGHosting from "./views/LDGHosting";
import AgentAI from "./views/AgentAI";
import LDGPlayer from "./components/LDGPlayer";

// Rutas definidas utilizando createHashRouter
const router = createHashRouter([
  {
    path: "/",
    element: <IndexView />,
    errorElement: <div>Page Not Found</div>,
  },
  // Agrega el resto de tus rutas aquí
  {
    path: "/pricing",
    element: <PricingView />,
  },
  {
    path: "/blog",
    element: <BlogView />,
  },
  {
    path: "/contact",
    element: <ContactView />,
  },
  {
    path: "/download",
    element: <DownloadView />,
  },
  {
    path: "/download-mobile",
    element: <DownloadMobileView />,
  },
  {
    path: "/licensing",
    element: <LicensingView />,
  },
  {
    path: "/company",
    element: <CompanyView />,
  },
  {
    path: "/ldg-hosting",
    element: <LDGHosting />,
  },
  {
    path: "/agent-ai",
    element: <AgentAI />,
  },

  {
    path: "/ldg-showcase",
    element: <LDGPlayer />,
  },
  {
    path: "*",
    element: <div>Not Found</div>,
  },
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
