import React from "react";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";
import SectionInformation from "../components/SectionInformation";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: true,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

class AgentAI extends React.Component {
  componentDidMount() {
    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }
  render() {
    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @import url(/css/normalize.css);
            @import url(/css/webflow.css);
            @import url(/css/ldg-7a93c9.webflow.css);
          `,
          }}
        />
        <span className="af-view">
          <NavBar />
          <div className="af-class-section-hero">
            <div className="af-class-container">
              <div className="af-class-text-center">
                <h1 className="af-class-hero-text">
                  <span className="af-class-text-gradient-1">Agent</span>
                </h1>
                <h1 className="af-class-hero-text">
                  <span className="af-class-text-gradient-2">AI</span>
                </h1>
              </div>
              <div className="af-class-small-container">
                <div className="af-class-text-center">
                  <div className="af-class-text-holder">
                    <h6 className="af-class-text-display">
                      Your Personal Assistant in Real Estate. Tailored to your
                      market and goals, Agent AI provides strategic guidance and
                      insights to drive your growth.
                    </h6>

                    <div className="af-class-preloader">
                      <div className="af-class-preloader-content">
                        <div className="af-class-preloader-line">
                          <div className="af-class-preloader-gradient" />
                        </div>
                        <img src="images/ldg.logo.png" loading="lazy" alt />
                      </div>
                    </div>
                    <h6 className="af-class-text-display">
                      ‍<strong>Fast, affordable, and effortless.</strong>
                    </h6>
                  </div>
                  <div className="af-class-button-container">
                    <a
                      href="http://app.listingdescriptiongenerator.com/"
                      target="_blank"
                      className="af-class-button af-class-empty w-button"
                    >
                      Get Started
                    </a>
                  </div>
                </div>

                <div className="af-class-product-demo-sticky">
                  <div className="af-class-product-image-container">
                    <div className="af-class-product-container af-class-desktop">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2F4.png?alt=media&token=10ba7052-d020-456a-8921-18a15cb6cc92"
                        loading="lazy"
                        sizes="(max-width: 479px) 77vw, (max-width: 767px) 74vw, (max-width: 991px) 75vw, (max-width: 1279px) 59vw, (max-width: 1439px) 771.9921875px, 880px"
                        alt
                        className="af-class-product-image"
                      />
                      <a
                        href="#"
                        className="af-class-play-icon-holder w-inline-block w-lightbox"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionInformation
            title="🔍 Discover AI-Powered Real Estate Insights"
            description=" Agent AI transforms how you interact with property data, delivering tailored market insights in minutes."
            menuItems={[
              {
                title: "Intelligent Market Analysis",
                description:
                  "Dive deep into market trends with Agent AI’s advanced analytical tools. Stay ahead with predictive insights.",
                icon: "images/aurora-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%204.03.22%E2%80%AFp.m..png?alt=media&token=23c340cd-7e1e-44bc-b71e-cf250aa57ba4",
              },
              {
                title: "Customized Strategy Development",
                description:
                  "Create growth-focused strategies with AI-driven data analysis tailored to your unique market and goals.",
                icon: "images/feedback-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2F554.png?alt=media&token=38b62165-52a1-4429-a64a-c892b68ae0a6",
              },
              {
                title: "Efficient Client Engagement",
                description:
                  "Enhance client relationships with personalized property suggestions and unique market insights.",
                icon: "images/integrated-icon.svg",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/listing-description-generator.appspot.com/o/images%2FScreenshot%202023-11-13%20at%204.11.02%E2%80%AFp.m..png?alt=media&token=4ca352b5-20f1-456b-8fbf-af1c3decf763",
              },
            ]}
          />

          <LDGFooter />
        </span>
      </>
    );
  }
}

export default AgentAI;
