/* eslint-disable */

import React, { useEffect } from "react";
import { createScope, map, transformProxies } from "./helpers";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

class LicensingView extends React.Component {
  componentDidMount() {
    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }
  render() {
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @import url(/css/normalize.css);
            @import url(/css/webflow.css);
            @import url(/css/ldg-7a93c9.webflow.css);
          `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-wrapper">
              <NavBar />
              <div className="af-class-section af-class-section-grey">
                <div className="af-class-container">
                  <h1>
                    <span className="af-class-text-gradient-2">Licensing</span>
                  </h1>
                  <div className="af-class-text-holder">
                    <div className="af-class-text-holder">
                      <h6 className="af-class-white-40">
                        <em>
                          LDG does not have licenses because you pay for what
                          you use. Join now!
                        </em>
                      </h6>
                    </div>
                    <div className="af-class-card-item">
                      <div className="af-class-card-item-content">
                        <div className="af-class-pricing-table-content">
                          <div className="af-class-pricing-header">
                            <h4>Start</h4>
                            <h4 className="af-class-pricing-fee">Free</h4>
                          </div>
                          <div className="af-class-white-40">
                            2 team members
                          </div>
                          <ul role="list" className="af-class-pricing-list">
                            <li className="af-class-list-item-with-check">
                              Unlimited forms
                            </li>
                            <li className="af-class-list-item-with-check">
                              Constant updates
                            </li>
                            <li className="af-class-list-item-with-check">
                              You can create a team
                            </li>
                            <li className="af-class-list-item-with-check">
                              $3 per Ticket
                            </li>
                          </ul>
                          <a
                            href="https://app.listingdescriptiongenerator.com/"
                            target="_blank"
                            className="af-class-button af-class-white-empty w-button"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LDGFooter />
          </div>
        </span>
      </span>
    );
  }
}

export default LicensingView;

/* eslint-enable */
