/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";
import NavBar from "../components/Navbar";
import LDGFooter from "../components/LDGFooter";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=642dd511bde01b6f57888589"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class CompanyView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/CompanyController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = CompanyView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "642dd511bde01bd05688859c";
    htmlEl.dataset["wfSite"] = "642dd511bde01b6f57888589";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = true;
        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      CompanyView.Controller !== CompanyView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ldg-7a93c9.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-wrapper">
              <NavBar />
              <div className="af-class-section af-class-section-grey">
                <div className="af-class-container">
                  <div className="w-layout-grid af-class-grid-10">
                    <div
                      id="w-node-_4479bb91-31c7-e730-eaed-fd4f95ad34b6-5688859c"
                      className="af-class-content"
                    >
                      <h1 className="af-class-text-gradient-1">About Us</h1>
                      <h5>
                        Our goal is to provide solutions for real estate brokers
                        and agents. With a focus on simplifying the listing
                        creation process, our listing generator streamlines and
                        automates the task, saving you time and effort.{" "}
                      </h5>
                      <h6 className="af-class-white-40">
                        LDG is your go-to tool for creating professional and
                        effective property listings.
                      </h6>
                      <div className="af-class-button-container af-class-small">
                        <a
                          href="mailto:marketing@listingdescriptiongenerator.com?subject=I%20need%20more%20information%20about%20LDG"
                          className="af-class-button af-class-empty w-button"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-section af-class-section-grey">
                <div className="af-class-container">
                  <div className="af-class-text-center">
                    <h2>Our Team</h2>
                    <div className="af-class-text-container">
                      <p className="af-class-grey-text">
                        The Dream team, we grow stronger everyday.
                      </p>
                    </div>
                    <div className="af-class-team-grid">
                      <div
                        id="w-node-abc278ba-c3d4-95c1-3178-b2d0ca50a5c4-5688859c"
                        className="af-class-team-card"
                      >
                        <img
                          src="images/hector.png"
                          loading="lazy"
                          sizes="(max-width: 479px) 79vw, (max-width: 767px) 190px, (max-width: 991px) 25vw, (max-width: 1439px) 165px, 270px"
                          srcSet="images/hector-p-500.png 500w, images/hector-p-800.png 800w, images/hector-p-1080.png 1080w, images/hector.png 1600w"
                          alt
                          className="af-class-team-member-image"
                        />
                        <div className="af-class-team-member-name">
                          Hector Beltrán
                        </div>
                        <div className="af-class-team-member-position">
                          Founder of LDG
                          <br />‍
                        </div>
                      </div>
                      <div
                        id="w-node-abc278ba-c3d4-95c1-3178-b2d0ca50a5cf-5688859c"
                        className="af-class-team-card"
                      >
                        <img
                          src="images/328938351_1673200986471178_7807424232088081004_n.jpg"
                          loading="lazy"
                          sizes="(max-width: 479px) 79vw, (max-width: 767px) 190px, (max-width: 991px) 25vw, (max-width: 1439px) 165px, 270px"
                          srcSet="images/328938351_1673200986471178_7807424232088081004_n-p-500.jpg 500w, images/328938351_1673200986471178_7807424232088081004_n-p-800.jpg 800w, images/328938351_1673200986471178_7807424232088081004_n.jpg 809w"
                          alt
                          className="af-class-team-member-image"
                        />
                        <div className="af-class-team-member-name">
                          Martin Garcia
                        </div>
                        <div className="af-class-team-member-position">
                          Software and Features Developer
                        </div>
                      </div>
                      <div
                        id="w-node-abc278ba-c3d4-95c1-3178-b2d0ca50a5d9-5688859c"
                        className="af-class-team-card"
                      >
                        <img
                          src="images/154988580_900249134136404_2358034292313398849_n.jpg"
                          loading="lazy"
                          sizes="(max-width: 479px) 79vw, (max-width: 767px) 190px, (max-width: 991px) 25vw, (max-width: 1439px) 165px, 270px"
                          srcSet="images/154988580_900249134136404_2358034292313398849_n-p-500.jpg 500w, images/154988580_900249134136404_2358034292313398849_n.jpg 640w"
                          alt
                          className="af-class-team-member-image"
                        />
                        <div className="af-class-team-member-name">
                          Michael Balicki
                        </div>
                        <div className="af-class-team-member-position">
                          Founder of LDG
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
           <LDGFooter />
          </div>
        </span>
      </span>
    );
  }
}

export default CompanyView;

/* eslint-enable */
